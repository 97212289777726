//@ts-nocheck
import React from "react";
import { Avatar } from "@chakra-ui/react";
import { BASE_API_URL } from "../../api/useAxios";
const Advisor = ({ advisors }) => {
  return (
    <div>
      <h1 className="font-bold mb-2">Advisors and Investors</h1>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-2">
        <p className={advisors.length === 0 ? "flex-block" : "hidden"}>
          {" "}
          "No data"
        </p>
        {advisors.map((value) => {
          return (
            <AdvisorCard
              key={value.id}
              name={value.name}
              image={value.picture}
            />
          );
        })}
      </div>
    </div>
  );
};

const AdvisorCard = (advisorInfo) => {
  return (
    <div className="h-fit w-fit px-4 md:px-6 py-1 bg-red-200  rounded-lg border border-sumuni-primary flex flex-col items-center">
      <Avatar
        size={"lg"}
        name={advisorInfo?.name}
        src={
          advisorInfo.image
            ? `${BASE_API_URL}/download/${advisorInfo.image}`
            : ""
        }
      />
      <h2 className="font-medium md:text-sm">{advisorInfo.name}</h2>
      <h5 className="md:text-sm">Advisor</h5>
    </div>
  );
};

export default Advisor;
