//@ts-nocheck
import React from "react";
import { BASE_API_URL } from "../../api/useAxios";
const Partner = ({ partners }) => {
  return (
    <div className={partners.length == 0 ? "hidden mt-2" : "mt-2"}>
      <h1 className="font-bold mb-2">Partners</h1>
      <div className="flex flex-wrap justify-between ">
        {partners.map((val, idx) => {
          return (
            <div key={idx}>
              <img
                className="w-auto h-20 object-contain"
                src={val.logo ? `${BASE_API_URL}/download/${val.logo}` : ""}
                alt={"img_alt"}
              />
              <h3 className="font-medium text-center">{val.name}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Partner;
