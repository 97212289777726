//@ts-nocheck
// export const calculateDayLeft = (startDate, endDate) => {
// 	startDate = new Date(startDate.split(" ")[0]);
// 	const startMonth = startDate.getMonth() + 1;
// 	const startDay = startDate.getDate();

// 	endDate = new Date(endDate.split(" ")[0]);
// 	const endMonth = endDate.getMonth() + 1;
// 	const endDay = endDate.getDate();

// 	if (endMonth > startMonth) {
// 		return 30 - startDay + endDay;
// 	} else {
// 		return endDay - startDay;
// 	}
// };

export const calculateDayLeft = (start_date, end_date) => {
  // Get the current date as a Date object
  const currentDate = new Date();

  // Add 30 days to the start_date and convert to a Date object
  const endDate = new Date(
    new Date(start_date).getTime() + 30 * 24 * 60 * 60 * 1000
  );

  // Calculate the difference in milliseconds between the two dates
  const diff = endDate.getTime() - currentDate.getTime();

  // Convert the difference to days and round down to the nearest integer
  const daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24));

  return daysLeft;
};
