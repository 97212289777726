import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Review from "./pages/Review";
import Withdraw from "./pages/Withdraw";
import Invest from "./pages/invest";
import Company from "./pages/entrepreneur";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {!sessionStorage.getItem("token") ? (
          <>
            <Route path="/*" element={<Login />}></Route>
          </>
        ) : (
          <>
            <Route path="/*" element={<Review />}></Route>
            <Route path="/review" element={<Review />}></Route>
            <Route path="/withdraw" element={<Withdraw />}></Route>
            <Route path="/company/:id" element={<Company />}></Route>
            <Route path="/campaign/:id" element={<Invest />}></Route>
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
