//@ts-nocheck

import React, { useState } from "react";
import { Avatar, Collapse } from "@chakra-ui/react";
import { BASE_API_URL } from "../../api/useAxios";

const Overview = ({ data }) => {
  const [showIndustries, setShowIndustries] = useState(false);
  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-4">
        <Avatar
          size={"lg"}
          name={data?.name}
          src={data.logo ? `${BASE_API_URL}/download/${data.logo}` : ""}
        />
        <div>
          <h1 className="mb-3 text-xl font-medium ">{data.name}</h1>
          <p className="text-justify mb-4">{data.bio || ""}</p>
          <p className="mb-4 font-medium">
            ETB {data.raised.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            Raised
          </p>
        </div>
      </div>
      <div>
        <h1 className="text-xl font-bold mb-4">Overview</h1>
        <p className="">{data.overview || ""}</p>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col gap-4">
          <h3 className="font-bold">Location</h3>
          <p>
            {data.startupLocation.city || ""}
            {data.startupLocation ? ", " : ""}
            {data.startupLocation.country || ""}
          </p>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-bold">Website</h3>
          <p>{data.website || ""}</p>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-bold">Phone Number</h3>
          <p>{data.phoneNumber || ""}</p>
        </div>
      </div>
      <div>
        <h3 className="mb-2 font-bold">Industry</h3>
        <Collapse
          startingHeight={data.industry.length !== 0 ? 50 : 40}
          in={showIndustries}
        >
          <div className="relative min-h-[5%] flex flex-wrap items-center mt-2 gap-4">
            {data.industry.length !== 0
              ? data.industry.map((val, i) => {
                  return (
                    <div>
                      <div className="bg-sumuni-red3 rounded-xl w-auto text-center p-2 font-semibold shadow hover:bg-red-100 ">
                        {val}
                      </div>
                    </div>
                  );
                })
              : "No industries selected, Please add your preferred industries"}
          </div>
        </Collapse>

        {data.industry.length >= 9 ? (
          <div className="h-[8vh] mt-4 border-t-[0.05em] flex items-center justify-center">
            <p
              onClick={() => setShowIndustries(!showIndustries)}
              className="mt-4 text-sm text-center font-semibold text-sumuni-primary cursor-pointer"
            >
              Show {showIndustries ? "Less" : "More"}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* <div className={data.fundraisingHighlights ? "flex-block" : "hidden"}>
        <h3 className=" font-bold">Fundraising Highlights</h3>
        <div className="flex flex-col gap-1">
          <p>Total Amount Raised: US $191,000</p>
          <p>Total Round Size: US $1,500,000</p>
          <p>Raise Description: Seed</p>
          <p>Minimum Investment: US $1,000 per investor</p>
          <p>Security Type: Convertible Note</p>
          <p>Valuation Cap: US $10,000,000</p>
          <p>Offering Type: Side by Side Offering</p>
        </div>
      </div> */}
    </div>
  );
};

export default Overview;
