import whiteLoadingElipse from "../../assets/white_loading_elipse.png";
import redLoadingElipse from "../../assets/red_loading_elipse.png";

function Button(props) {
  const getLoadingIcon = () => {
    return (
      <>
        {props.loading ? (
          props.hollow ? (
            <img src={redLoadingElipse} className="z-20 w-5 h-5 animate-spin" />
          ) : (
            <img
              src={whiteLoadingElipse}
              className="z-20 w-5 h-5 animate-spin"
            />
          )
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <>
      {props.className ? (
        <button
          type={props.type ? props.type : "button"}
          disabled={props.disabled}
          className={`flex flex-row gap-2 items-center justify-center uppercase font-semibold text-center align-center py-2 px-8 rounded-md shadow-sm w-fit  hover:shadow-lg hover:shadow-red-100 duration-300
          active:bg-opacity-80 cursor-pointer ease-in-out active:scale-95  ${
            props.className
          } ${
            props.disabled
              ? "hover:shadow-md bg-opacity-80 !bg-sumuni-gray-dark"
              : ""
          } ${
            props.hollow
              ? "text-sumuni-primary bg-white border border-sumuni-primary hover:bg-gray-100"
              : "bg-sumuni-primary  text-white"
          }`}
          onClick={props.handleClick}
        >
          {getLoadingIcon()}
          <span>{props.title}</span>
        </button>
      ) : (
        <button
          type={props.type ? props.type : "button"}
          disabled={props.disabled}
          className={`flex flex-row gap-2 items-center justify-center uppercase font-semibold text-center align-center py-2 px-8 rounded-md shadow-sm w-fit  hover:shadow-lg hover:shadow-red-100 duration-300
          active:bg-opacity-80 cursor-pointer ease-in-out active:scale-95  ${
            props.disabled
              ? "hover:shadow-md bg-opacity-80 !bg-sumuni-gray-dark"
              : ""
          } ${
            props.hollow
              ? "text-sumuni-primary bg-white border border-sumuni-primary hover:bg-gray-50  "
              : "bg-sumuni-primary text-white"
          }`}
          onClick={props.handleClick}
        >
          {getLoadingIcon()}
          <span>{props.title}</span>
        </button>
      )}
    </>
  );
}

export default Button;
