//@ts-nocheck
import React from "react";

const BusinessModel = ({ businessModel }) => {
  return (
    <div>
      <h1 className="text-xl font-bold mb-2">Business Model</h1>
      <p>{businessModel || "No data"}</p>
    </div>
  );
};

export default BusinessModel;
