//@ts-nocheck
import React, { useState, useEffect } from "react";
import useAxios from "../../api/useAxios";
import Loading from "../../components/Loading";
import { calculateDayLeft } from "../../libs/calculateDate";
import PDFViewer from "../../components/common/PDFViewer";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { BASE_API_URL } from "../../api/useAxios";
import Header from "../../components/header";

export default function Invest() {
  const [pitchDeckIdx, setPitchDeckIdx] = useState(0);
  const [dayLeft, setDayLeft] = useState(0);
  const [camp, setCamp] = useState();
  const [pitchDeck, setPitchDeck] = useState([]);

  const id = useParams();

  const [resA, errorA, msgA, isLoadedA, isLoadingA, getCampaigns] = useAxios(
    `/companies/1/campaign`,
    "get"
  );

  const slidePitchDeck = (i) => {
    if (pitchDeckIdx >= 0 && pitchDeckIdx < pitchDeck.length) {
      setPitchDeckIdx(pitchDeckIdx + i);
    }
  };

  const getSingleCampaign = (campaignId) => {
    let url = `/companies/1/campaign/${campaignId}`;

    getCampaigns(
      null,
      (response) => {
        let res = response.campaign;

        setDayLeft(calculateDayLeft(res.start_date, res.end_date));
        setPitchDeck(res.pitch_deck);
        setCamp(res);
      },
      url
    );
  };
  useEffect(() => {
    if (id.id) {
      getSingleCampaign(id.id);
    }
  }, [id]);

  return (
    <>
      <div className="bg-white z-30 md:px-12 px-2">
        <Header />
      </div>
      <div className="relative min-h-screen md:px-12">
        <Loading
          isLoading={isLoadingA}
          isLoaded={isLoadedA}
          error={errorA}
          msg={msgA}
          res={resA}
          tryAgain={getSingleCampaign}
          output={
            resA ? (
              <div className="text-xs md:text-base">
                <div className="md:flex gap-10 mb-6">
                  <div className="w-full">
                    {pitchDeck.length > 0 && pitchDeck[0] ? (
                      <DisplayPitchDeck file={pitchDeck[pitchDeckIdx]} />
                    ) : (
                      <img
                        className="w-full h-48 md:h-64"
                        src={`${BASE_API_URL}/download/${camp.picture}`}
                        alt="campaign"
                      />
                    )}

                    <div className="flex justify-between items-center my-2">
                      <div className="flex gap-2">
                        <div className="border rounded-full w-8 h-8">
                          {pitchDeckIdx > 0 ? (
                            <button type="button" className="h-full w-full">
                              <BiLeftArrow
                                style={{ color: "#dc2626" }}
                                onClick={() => {
                                  slidePitchDeck(-1);
                                }}
                                className="mx-auto"
                              />
                            </button>
                          ) : (
                            <button type="button" className="h-full w-full">
                              <BiLeftArrow
                                style={{ color: "#fca5a5" }}
                                className="mx-auto"
                              />
                            </button>
                          )}
                        </div>
                        <div className=" border rounded-full  text-white w-8 h-8">
                          {pitchDeckIdx < pitchDeck.length - 1 ? (
                            <button type="button" className="h-full w-full">
                              <BiRightArrow
                                style={{ color: "#dc2626" }}
                                onClick={() => {
                                  slidePitchDeck(1);
                                }}
                                className="mx-auto"
                              />
                            </button>
                          ) : (
                            <button type="button" className="h-full w-full">
                              <BiRightArrow
                                style={{ color: "#fca5a5" }}
                                className="mx-auto"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                      <p>PITCH DECK</p>
                      {pitchDeck.length > 0 ? (
                        <a
                          href={`${BASE_API_URL}/download/${pitchDeck[pitchDeckIdx].url}`}
                          target="_blank"
                          rel="noreferrer"
                          download={pitchDeck[pitchDeckIdx]}
                        >
                          <button className="font-medium rounded-sm border bg-sumuni-primary text-white text-sumuni-text-red px-2 py-1">
                            Download
                          </button>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-4">
                    <div className="">
                      <h1 className="font-medium text-3xl mb-1">
                        {camp?.name}
                      </h1>
                      <p>{camp.company.bio}</p>
                    </div>

                    <div className="flex justify-between">
                      <p>
                        ETB{" "}
                        {camp.raised
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        /
                        {camp.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        Raised
                      </p>
                      {camp.raised < camp.amount && (
                        <p>
                          ETB{" "}
                          {(camp.amount - camp.raised)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          Remaining
                        </p>
                      )}
                      {dayLeft > 0 ? (
                        <p>{dayLeft} days left</p>
                      ) : (
                        <p className="text-sumuni-red1">Expired</p>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <Link to={`/company/${camp.company_id}`}>
                        <button className="rounded-sm border bg-sumuni-primary text-white text-sumuni-text-red px-2 py-1">
                          View Company Detail
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mb-6 flex flex-col">
                  <h1 className="text-xl font-medium mb-4">Fund Details</h1>
                  <p>{camp.fund_details}</p>
                </div>
              </div>
            ) : (
              ""
            )
          }
        />
      </div>
    </>
  );
}

const DisplayPitchDeck = ({ file }) => {
  return (
    <>
      {file.type === "image" ? (
        <img
          className="w-full h-48 md:h-64"
          src={`${BASE_API_URL}/download/${file.url}`}
          alt="campaign"
        />
      ) : file.type === "video" ? (
        <video className="h-48 w-full md:h-64" controls>
          <source
            src={`${BASE_API_URL}/download/${file.url}`}
            type="video/mp4"
          />
          <source
            src={`${BASE_API_URL}/download/${file.url}`}
            type="video/webm"
          />
          Sorry, your browser doesn't support videos.
        </video>
      ) : file.type === "pdf" ? (
        <PDFViewer file={file.url} />
      ) : (
        ""
      )}
    </>
  );
};
