export const areInputsValid = (msgs) => {
  console.log(msgs);
  for (let i of msgs) {
    if (i !== undefined) {
      return false;
    }
  }

  return true;
};
