//states
import { useEffect, useState, useRef } from "react";

//icons
import { FaBars, FaCaretRight, FaAngleUp } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaMoneyCheck } from "react-icons/fa";
import { MdReviews, MdOutlineReviews } from "react-icons/md";

//images
import sumuniLogo from "../../assets/logo.svg";
import img1 from "../../assets/coverImage.jpg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../api/useAxios";
import Button from "../Button";

function Header() {
  const navigate = useNavigate();
  let location = useLocation();
  const [state, setState] = useState(false);
  const navRef = useRef();

  const [headerSlidderShown, setHeaderSlidderShown] = useState(false);
  const [currentPage, setCurrentPage] = useState();

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [res, error, msg, isLoaded, isLoading, apiCall] = useAxios(
    `/admin/logout`,
    "post"
  );

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  useEffect(() => {
    // Sticky strick
    const customStyle = [
      "sticky-nav",
      "fixed",
      "border-b",
      "w-full",
      "bg-white",
      "ease-in-out",
      "duration-300",
    ];
    window.onscroll = () => {
      if (window.scrollY > 80) navRef.current.classList.add(...customStyle);
      else navRef.current.classList.remove(...customStyle);
    };
  }, [state]);

  const handleLogout = () => {
    apiCall(null, () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      window.location.reload();
    });
  };

  const navLinks = [
    {
      url: "/review",
      title: "Review",
      icon1: <MdReviews size={20} color="red" />,
      icon2: <MdOutlineReviews size={20} />,
    },
    {
      url: "/withdraw",
      title: "Withdrawals",
      icon1: <BiMoneyWithdraw size={20} color="red" />,
      icon2: <FaMoneyCheck size={20} />,
    },
  ];

  return (
    /* Header */
    <div
      className={`w-full flex flex-col relative ${
        headerSlidderShown ? "h-screen" : ""
      }`}
    >
      <div
        ref={navRef}
        className="flex flex-row z-50 justify-between items-center py-2"
      >
        <div className="flex flex-row items-center gap-1">
          <Link to="/">
            <div className="flex flex-row items-center gap-1 min-w-fit">
              <img src={sumuniLogo} className="w-2/3" />
              {/* <p className="text-xl sm:text-2xl font-semibold">Andlaye</p> */}
            </div>
          </Link>
        </div>

        <span
          className="text-xl lg:hidden hover:cursor-pointer"
          onClick={() => setHeaderSlidderShown(!headerSlidderShown)}
        >
          {headerSlidderShown ? <FaAngleUp /> : <FaBars />}
        </span>

        <div className="flex-row gap-10 items-center hidden lg:flex">
          <div className="gap-5 flex">
            {navLinks.map((val, index) => (
              <Link to={val.url}>
                <div
                  className={`${
                    currentPage == val.url ? "text-sumuni-primary" : ""
                  }  flex-col items-center  hover:cursor-pointer hidden lg:flex`}
                >
                  {currentPage == val.url ? val.icon1 : val.icon2}
                  <p className="font-semibold">{val.title}</p>
                </div>
              </Link>
            ))}
          </div>

          {sessionStorage.getItem("token") != null ? (
            <>
              <div className="flex flex-col relative">
                <div
                  className="flex flex-row items-center gap-2  rounded-full py-1 px-2 hover:cursor-pointer"
                  onClick={() => setDropDownOpen(!dropDownOpen)}
                >
                  <img src={img1} className="w-9 h-9 rounded-full" />
                  {dropDownOpen ? (
                    <p className="font-semibold">
                      {JSON.parse(sessionStorage.getItem("name"))}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {dropDownOpen ? (
                  <div className="w-full flex flex-col absolute top-14 bg-white shadow-lg rounded-b-lg">
                    <button
                      className="capitalize py-2 text-sumuni-primary hover:bg-sumuni-primary-lighter font-semibold"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <>
              <button className="text-sm rounded-lg text-white bg-sumuni-primary px-5 py-2 font-semibold uppercase">
                <Link to="/otp">Sign in</Link>
              </button>
            </>
          )}
        </div>
      </div>
      {headerSlidderShown ? (
        <div className="w-full flex flex-col items-start justify-between relative h-full pt-3">
          <div className="w-full flex flex-col">
            {sessionStorage.getItem("token") != null ? (
              <div className="flex flex-row items-center gap-2 bg-white shadow-sumuni-gray-dark shadow-md py-2 px-2 mx-5 hover:cursor-pointer">
                <img src={img1} className="w-9 h-9 rounded-full" />
                <p className="font-semibold">
                  {JSON.parse(sessionStorage.getItem("name"))}
                </p>
              </div>
            ) : (
              ""
            )}

            <div className="w-full flex flex-col">
              {navLinks.map((val, index) => (
                <Link to={val.url}>
                  <div
                    className={`${
                      currentPage == val.url ? "text-sumuni-primary" : ""
                    } w-full flex flex-row justify-between items-center px-6 py-6 border-b hover:cursor-pointer`}
                  >
                    <p className="font-semibold">{val.title}</p>
                    <FaCaretRight />
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* <button className="text-sm rounded-lg text-white bg-sumuni-primary px-5 py-2 font-semibold uppercase"><Link to="/otp">Sign in</Link></button> */}
          <div className="w-full px-6 mb-12">
            {sessionStorage.getItem("token") != null ? (
              <Button
                title="Logout"
                hollow={true}
                handleClick={() => handleLogout()}
                className="w-full"
              />
            ) : (
              <Button
                title="Sign in"
                handleClick={() => navigate("/login")}
                className="w-full"
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
