import useValidatedState from "../../hooks/useValidatedState";
import useAxios from "../../api/useAxios";
import { areInputsValid } from "../../libs/checkValidationMsgs";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
export default function Login() {
  const toast = useToast();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);

  const [email, setEmail, emailValidationMsg] = useValidatedState({
    type: "email",
    required: true,
    fieldName: "email",
  });
  const [password, setPassword, passwordValidationMsg] = useValidatedState({
    required: true,
    fieldName: "password",
  });

  const [res, error, msg, isLoaded, isLoading, apiCall] = useAxios(
    `/admin/signin`,
    "post"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setCheck(true);
    if (hasInvalidInput() === false) {
      apiCall(
        {
          email: email,
          password: password,
        },
        (res) => {
          sessionStorage.setItem("token", res.token);
          sessionStorage.setItem(
            "name",
            JSON.stringify(res.user.first_name + " " + res.user.last_name)
          );
          toast({
            title: `You have successfully signed in`,
            status: "success",
            isClosable: true,
          });
          setTimeout(() => {
            navigate("/");
            window.location.reload();
          }, 1000);

          // console.log(res);
        },
        null,
        () => {
          toast({
            title: `Email or Password incorrect`,
            status: "error",
            isClosable: true,
          });
          // console.log(err);
        }
      );
    }
  };

  const hasInvalidInput = () => {
    return !areInputsValid([emailValidationMsg, passwordValidationMsg]);
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
        <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
          <h3 className="mb-4 text-xl font-semibold text-center sm:mb-6 sm:text-2xl">
            Login
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-1 sm:mb-2">
              <label htmlFor="email" className="inline-block mb-1 font-medium">
                E-mail
              </label>
              <input
                placeholder="john.doe@example.org"
                required
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-purple-400 focus:outline-none focus:shadow-outline"
                id="email"
                name="email"
              />
              {check && (
                <p
                  className="text-sm font-semibold text-sumuni-primary
              "
                >
                  {emailValidationMsg}
                </p>
              )}
            </div>

            <div className="mb-1 sm:mb-2">
              <label
                htmlFor="firstName"
                className="inline-block mb-1 font-medium"
              >
                Password
              </label>
              <input
                placeholder="********"
                required
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-purple-400 focus:outline-none focus:shadow-outline"
                id="firstName"
                name="firstName"
              />
              {check && (
                <p
                  className="text-sm font-semibold text-sumuni-primary
              "
                >
                  {passwordValidationMsg}
                </p>
              )}
            </div>
            <div className="mt-4 mb-2 sm:mb-4">
              <button
                type="submit"
                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-sumuni-primary hover:bg-red-500 focus:shadow-outline focus:outline-none"
              >
                Login
              </button>
              {isLoading ? "loading..." : ""}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
