import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import { zoomPlugin } from "@react-pdf-viewer/zoom";

import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { BASE_API_URL } from "../../api/useAxios";
import { useToast } from "@chakra-ui/react";

export default function PDFViewer({ file, type }) {
  const toast = useToast();

  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  const handleLoadError = () => {
    toast({
      title: `Please disable your download manager to view this file. eg:IDM`,
      status: "error",
      isClosable: true,
    });
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <ZoomOutButton />
        <ZoomPopover />
        <ZoomInButton />
      </div>
      <div className={type === "room" ? "h-[70vh] w-full" : "h-72"}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={`${BASE_API_URL}/download/${file}`}
            plugins={[zoomPluginInstance]}
            renderError={handleLoadError}
          ></Viewer>
        </Worker>
      </div>
    </>
  );
}
