import axios from "axios";
import { useState, useMemo } from "react";

export const BASE_API_URL = "https://andlayeapi.sumuni.net/api";
// export const BASE_API_URL = "http://127.0.0.1:8000/api";
// export const BASE_API_URL = "http://45.90.108.249:8020/api";

// export const CHAT_API_URL = "http://127.0.0.1:8000/api";

const ERR_MSGS = {
  0: "Network error. Check your connection",
  500: "Internal server error",
  400: "Invalid input",
  401: "User not signed in yet",
  403: "Access to page not allowed",
  404: "Requested resource not found",
};

const useAxios = (url, method) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const headers = useMemo(
    () => ({
      Accept: "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "Content-type": "application/json",
    }),
    []
  );

  const apiCall = useMemo(
    () =>
      async (payload, callback, urlNew = null, failCallback = null) => {
        setData(null);
        setMsg(null);
        setError(null);
        setLoaded(false);
        setIsLoading(true);

        try {
          let d = {
            method,
            url: `${BASE_API_URL}${urlNew || url}`,
            headers,
          };

          if (method === "get") {
            d.params = payload;
          } else if (method === "form") {
            d.method = "post";
            headers["Content-type"] = "multipart/form-data";
            d.data = payload;
          } else {
            d.data = payload;
          }

          const response = await axios.request(d);

          if (
            method === "get" &&
            (response.data == null ||
              response.data.length == 0 ||
              (response.data?.data && response.data?.data.length == 0))
          ) {
            setMsg("No result found");
          }

          setData(response.data);
          callback(response.data);
        } catch (error) {
          let code = error.response.status;

          let msg = ERR_MSGS[code] ?? "Unknown error has occured";

          // if (msg === "User not signed in yet") {
          //   window.location.reload();
          // }

          setError(msg);

          if (failCallback) {
            failCallback(error.response);
          }
        } finally {
          setLoaded(true);
          setIsLoading(false);
        }
      },
    [url, method, headers]
  );

  return [data, error, msg, loaded, isLoading, apiCall];
};

export default useAxios;
