import { useEffect, useRef, useState } from "react";

const useValidatedState = ({
  defaultVal = undefined,
  required = false,
  type = "string",
  max = -1,
  min = -1,
  multipleTypes = null,
  fieldName = "",
  anotherField = null,
  anotherFieldName = null,
}) => {
  const mailformat =
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/;
  const urlFormat =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  const [value, setValue] = useState();

  const [invalidMsg, setInvalidMsg] = useState(" ");

  const sanitizeInput = (inp) => {
    // if (typeof inp === 'string') {
    //     inp = inp.trim();
    //     inp.trimStart
    // }

    setValue(inp);
  };

  const validateType = (type) => {
    // validate input type
    if (type === "int") {
      let i = parseInt(value);

      if (isNaN(i) || value.toString().length !== i.toString().length) {
        return `${fieldName} must be integer`;
      }
    } else if (type === "float") {
      let i = parseFloat(value);

      if (isNaN(i) || value.toString().length !== i.toString().length) {
        return `${fieldName} must be decimal number`;
      }
    } else if (type === "string") {
      if (typeof value !== "string") {
        return `${fieldName} must be text`;
      }
    } else if (type === "email") {
      if (!value.match(mailformat)) {
        return `Incorrect email format`;
      }
    } else if (type === "url") {
      if (!value.match(urlFormat)) {
        return `Incorrect URL format`;
      }
    } else if (type === "array") {
      if (typeof value !== "object") {
        return `${fieldName} must be a list`;
      }
    } else if (type === "date") {
      if (typeof value !== "object") {
        return `${fieldName} must be date`;
      }
    } else {
      return null;
    }
  };

  const validateInput = () => {
    if (
      required === true &&
      (value === null || value === undefined || value.length == 0)
    ) {
      setInvalidMsg(`${fieldName} is required`);
      return;
    } else if (
      required === false &&
      (value === null || value === undefined || value.length == 0)
    ) {
      setInvalidMsg();
      return;
    }

    // validate input type
    if (multipleTypes) {
      let isValid = false;
      let msg = "";
      for (let i = 0; i < multipleTypes.length; i++) {
        let tp = multipleTypes[i];

        let err = validateType(tp);

        if (i < multipleTypes.length - 1) {
          msg += `${tp}, `;
        } else {
          msg += `or ${tp}`;
        }
        console.log(err);
        isValid = isValid || !err;
      }
      console.log(isValid);

      if (!isValid) {
        setInvalidMsg(`${fieldName} must be ${msg}`);
        return;
      }
    } else if (type) {
      let err = validateType(type);

      if (err) {
        setInvalidMsg(err);
        return;
      }
    } else {
      setInvalidMsg("Unknown input type");
      return;
    }

    // if (type === 'int') {
    //     let i = parseInt(value);

    //     if (isNaN(i) || value.toString().length !== i.toString().length) {
    //         setInvalidMsg(`${fieldName} must be integer`)
    //         return;
    //     }
    // } else if (type === 'float') {
    //     let i = parseFloat(value);

    //     if (isNaN(i) || value.toString().length !== i.toString().length) {
    //         setInvalidMsg(`${fieldName} must be decimal number`)
    //         return;
    //     }
    // } else if (type === 'string') {
    //     if (typeof value !== 'string') {
    //         setInvalidMsg(`${fieldName} must be text`)
    //         return;
    //     }
    // } else if (type === 'email') {
    //     if (!value.match(mailformat)) {
    //         setInvalidMsg(`Incorrect email format`)
    //         return;
    //     }
    // } else if (type === 'url') {
    //     if (!value.match(urlFormat)) {
    //         setInvalidMsg(`Incorrect URL format`)
    //         return;
    //     }
    // } else if (type === 'array') {
    //     if (typeof value !== 'object') {
    //         setInvalidMsg(`${fieldName} must be a list`)
    //         return;
    //     }
    // } else {
    //     setInvalidMsg('Unknown field type')
    //     return;
    // }

    if (type === "int" || type === "float") {
      if (min != -1 && value < min) {
        setInvalidMsg(`${fieldName} must have a value greater than ${min}`);
        return;
      }

      if (max != -1 && value > max) {
        setInvalidMsg(`${fieldName} must have a value less than ${max}`);
        return;
      }
    } else if (type === "array") {
      if (min != -1 && value.length < min) {
        setInvalidMsg(`${fieldName} must have atleast ${min} elements`);
        return;
      }

      if (max != -1 && value.length > max) {
        setInvalidMsg(`${fieldName} must have a maximum of ${max} elements`);
        return;
      }
    } else {
      if (min != -1 && value.length < min) {
        setInvalidMsg(`${fieldName} must have atleast ${min} characters`);
        return;
      }

      if (max != -1 && value.length > max) {
        setInvalidMsg(`${fieldName} must have maximum of ${max} characters`);
        return;
      }
    }

    if (anotherField !== null) {
      if (value !== anotherField) {
        setInvalidMsg(`${fieldName} must be the same as ${anotherFieldName}`);
        return;
      }
    }

    setInvalidMsg();
  };

  useEffect(() => {
    validateInput();

    if (defaultVal !== undefined && (value === undefined || value === null)) {
      setValue(defaultVal);
    }
  }, [value, anotherField]);

  return [value, sanitizeInput, invalidMsg];
};

export default useValidatedState;
