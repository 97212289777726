//@ts-nocheck
import React from "react";

const Market = ({ data }) => {
  return (
    <div>
      <h1 className="text-xl font-bold mb-2">Market Opportunity</h1>
      <div className="">
        <h3 className="font-bold my-2">Total Available Market</h3>
        <p>{data.totalAvailableMarket || "No data"}</p>
      </div>
      <div className="">
        <h3 className="font-bold my-2">Service Available Market</h3>
        <p>{data.serviceAvailableMarket || "No data"}</p>
      </div>
      <div className="">
        <h3 className="font-bold my-2">Serviceable Obtainable Market</h3>
        <p>{data.serviceableAvailableMarket || "No data"}</p>
      </div>
    </div>
  );
};

export default Market;
