//@ts-nocheck
import React, { useState, useEffect } from "react";

import Overview from "../../components/entrepreneur/Overview";
import Problem from "../../components/entrepreneur/Problem";
import Solution from "../../components/entrepreneur/Solution";
import Traction from "../../components/entrepreneur/Traction";
import Market from "../../components/entrepreneur/Market";
import Partner from "../../components/entrepreneur/Partner";
import Team from "../../components/entrepreneur/Team";
import Advisor from "../../components/entrepreneur/Advisor";
import Fundraising from "../../components/entrepreneur/Fundraising";
import Fund from "../../components/entrepreneur/Fund";
import DataRoom from "../../components/entrepreneur/DataRoom";
import BusinessModel from "../../components/entrepreneur/BusinessModel";
import { useParams } from "react-router-dom";
import useAxios from "../../api/useAxios";
import Loading from "../../components/Loading";
import Header from "../../components/header";

const Entrepreneur = () => {
  const id = useParams();

  const [overviewData, setOverviewData] = useState({});
  const [partners, setPartners] = useState([]);
  const [marketOpportunity, setMarketOpportunity] = useState({});
  const [fundraisingDescription, setFundraisingDescription] = useState({});
  const [files, setFiles] = useState({});
  const [comp, setComp] = useState({});

  const [resA, errorA, msgA, isLoadedA, isLoadingA, apiCall] = useAxios(
    `/companies/1`,
    "get"
  );

  const fetchCompanyDetail = (campid, type) => {
    const url = `/admin/company/${campid}`;

    apiCall(
      null,
      (res) => {
        setOverviewData({
          logo: res?.logo,
          name: res?.name,
          bio: res?.bio,
          raised: res?.raised,
          overview: res?.overview,
          startupLocation: res?.startup_location,
          website: res?.website_link,
          phoneNumber: res?.phone_number,
          industry: res?.industry,
          fundraisingHighlights: res?.fundraising_highlights,
        });
        setMarketOpportunity({
          serviceAvailableMarket: res.service_available_market,
          serviceableAvailableMarket: res.serviceable_available_market,
          totalAvailableMarket: res.total_available_market,
        });
        setPartners(res.partners);
        setFundraisingDescription({
          roundType: res.round_type,
          fundingSize: res.funding_size,
          raisedToDate: res.raised_to_date,
        });

        setFiles({
          documentations: res.documentations,
          businessLicense: res.business_license,
          collateral: res.collateral,
          taxClearance: res.tax_clearance,
        });

        setComp(res);
      },
      url
    );
  };

  useEffect(() => {
    fetchCompanyDetail(id.id, "loggedIn");
  }, [id]);

  return (
    <div className="text-xs md:text-base">
      <div className="bg-white z-30 md:px-12 px-2">
        <Header />
      </div>
      <div className="relative min-h-screen">
        <Loading
          isLoading={isLoadingA}
          isLoaded={isLoadedA}
          error={errorA}
          msg={msgA}
          res={resA}
          tryAgain={fetchCompanyDetail}
          output={
            resA ? (
              <>
                <div className="md:flex ">
                  <div className="h-full  sticky top-[9vh] px-3 py-4 bg-red-100 hidden md:flex md:flex-col">
                    <h1 className="font-bold pl-5 mb-2">Contents</h1>
                    <ul className="space-y-1">
                      <li>
                        <a
                          href="#overview"
                          className="flex items-center p-2 font-normal text-gray-900 rounded-lg focus:text-sumuni-primary hover:bg-gray-100 "
                        >
                          <span className="ml-3">Overview</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#problem"
                          className="flex items-center p-2 font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Problem</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#solution"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Solution</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#traction"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Traction</span>
                        </a>
                      </li>

                      <li>
                        <a
                          href="#business_model"
                          className="flex items-center p-2 font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                        >
                          <span className="ml-3"> Business Model</span>
                        </a>
                      </li>

                      <li>
                        <a
                          href="#marketOpportunity"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Market Opportunity</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#partners"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Partners</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#team"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Team</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#advisors"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Advisors and Investors</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#fundraising"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Fundraising Description</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#fund"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Use of Fund</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#dataRoom"
                          className="flex items-center p-2  font-normal text-gray-900 rounded-lg  focus:text-sumuni-red1 hover:bg-gray-100 "
                        >
                          <span className="ml-3">Data Room</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="flex flex-col gap-12 md:w-2/3 md:mx-auto">
                    <section id="overview">
                      <Overview data={overviewData} />
                    </section>
                    <section id="problem">
                      <Problem problem={comp.problem} />
                    </section>
                    <section id="solution">
                      <Solution solution={comp.solution} />
                    </section>
                    <section id="traction">
                      <Traction traction={comp.market_traction} />
                    </section>
                    <section id="business_model">
                      <BusinessModel businessModel={comp.business_model} />
                    </section>
                    <section id="marketOpportunity">
                      <Market data={marketOpportunity} />
                    </section>
                    <section id="partners">
                      <Partner partners={partners} />
                    </section>
                    <section id="team">
                      <Team team={comp.team} />
                    </section>
                    <section id="advisors">
                      <Advisor advisors={comp.advisors} />
                    </section>
                    <section id="fundraising">
                      <Fundraising data={fundraisingDescription} />
                    </section>
                    <section id="fund">
                      <Fund fund={comp.use_of_fund} />
                    </section>

                    <section id="dataRoom">
                      <DataRoom data={files} />
                    </section>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          }
        />
      </div>
    </div>
  );
};

export default Entrepreneur;
