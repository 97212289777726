import Button from "../Button";
import { useEffect } from "react";
function Loading(props) {
  useEffect(() => {}, [props.res]);

  return (
    <>
      {props.isLoading ? (
        <div className="flex flex-col items-center text-gray-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <span className="block w-12 h-12 border-4 rounded-full shadow border-t-sumuni-red animate-spin"></span>
          <h1 className="mt-6 text-lg tracking-widest uppercase">
            Processing...
          </h1>
        </div>
      ) : props.isLoaded ? (
        props.error ? (
          <div className="flex flex-col gap-4 items-center justify-center">
            <p className="text-xl text-center font-semibold w-full text-red">
              {props.error}
            </p>
            <Button
              hollow={true}
              title="Try again"
              handleClick={() => props.tryAgain()}
            />
          </div>
        ) : props.msg ? (
          <p className="text-xl text-center font-semibold w-full">
            {props.msg}
          </p>
        ) : props.res ? (
          props.output
        ) : (
          <div className="flex flex-col gap-4 items-center justify-center">
            <p className="text-xl text-center font-semibold w-full text-red">
              Internal server error
            </p>
            <Button
              hollow={true}
              title="Try again"
              handleClick={() => props.tryAgain()}
            />
          </div>
        )
      ) : (
        "Unknown error has occured"
      )}
    </>
  );
}

export default Loading;
