//@ts-nocheck
import React from "react";

const Problem = ({ problem }) => {
	return (
		<div>
			<h1 className='text-xl font-bold mb-2'>Problem</h1>
			<p>{problem || "No data"}</p>
		</div>
	);
};

export default Problem;
