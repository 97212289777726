//@ts-nocheck
import React from "react";

const Solution = ({ solution }) => {
	return (
		<div>
			<h1 className='text-xl font-bold mb-2'>Solution</h1>
			<p>{solution || "No data"}</p>
		</div>
	);
};

export default Solution;
