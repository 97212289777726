//@ts-nocheck
import React from "react";

import { BASE_API_URL } from "../../api/useAxios";
import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import PDFViewer from "../common/PDFViewer";
import pdfIcon from "../../assets/pdf_icon.svg";

const DataRoom = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = React.useState(null);

  return (
    <div className="">
      <h1 className="font-bold mb-2">Data Room</h1>
      <div className="bg-sumuni-red3 divide-y divide-slate-200">
        <div className="flex justify-between w-full px-4 py-2">
          <p>Name</p>

          <p className="">Type</p>
        </div>
        {data.documentations ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setFile(data.documentations);
              onOpen();
            }}
          >
            <div className="flex justify-between px-4 py-2">
              <div className="flex gap-1">
                <img className="w-4 md:w-6" src={pdfIcon} alt="Document Icon" />
                <p className="self-center">Documentation</p>
              </div>
              <p className="pr-2">pdf</p>
            </div>
          </div>
        ) : (
          ""
        )}
        {data.businessLicense ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setFile(data.businessLicense);
              onOpen();
            }}
          >
            <div className="flex justify-between px-4 py-2">
              <div className="flex gap-1">
                <img className="w-4 md:w-6" src={pdfIcon} alt="Document Icon" />
                <p className="self-center">Business License</p>
              </div>
              <p className="pr-2">pdf</p>
            </div>
          </div>
        ) : (
          ""
        )}
        {data.collateral ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setFile(data.collateral);
              onOpen();
            }}
          >
            <div className="flex justify-between px-4 py-2">
              <div className="flex gap-1">
                <img className="w-4 md:w-6" src={pdfIcon} alt="Document Icon" />
                <p className="self-center">Collateral</p>
              </div>
              <p className="pr-2">pdf</p>
            </div>
          </div>
        ) : (
          ""
        )}
        {data.taxClearance ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setFile(data.taxClearance);
              onOpen();
            }}
          >
            <div className="flex justify-between px-4 py-2">
              <div className="flex gap-1">
                <img className="w-4 md:w-6" src={pdfIcon} alt="Document Icon" />
                <p className="self-center">Tax Clearance</p>
              </div>
              <p className="pr-2">pdf</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent className="py-2">
          <ModalHeader className="text-center">Data Room</ModalHeader>
          <ModalCloseButton color={"#FA2929"} />
          <PDFViewer file={file} type="room" />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DataRoom;
