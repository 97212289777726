//@ts-nocheck
import React from "react";
import { BASE_API_URL } from "../../api/useAxios";
import { Avatar } from "@chakra-ui/react";
const Team = ({ team }) => {
  return (
    <div>
      <h1 className="font-bold mb-2">Team</h1>
      <div className="flex flex-col gap-5">
        <p className={team.length === 0 ? "flex-block" : "hidden"}>No data</p>
        {team.map((value) => {
          return (
            <MemberCard
              key={value.id}
              name={value.name}
              role={value.role}
              bio={value.description}
              image={value.picture}
            />
          );
        })}
      </div>
    </div>
  );
};

const MemberCard = (teamMember) => {
  return (
    <div className="flex gap-3">
      <div className="h-fit bg-red-200 p-2 rounded-lg border border-sumuni-red1 flex items-center">
        <Avatar
          size={"lg"}
          name={teamMember?.name}
          src={
            teamMember.image
              ? `${BASE_API_URL}/download/${teamMember.image}`
              : ""
          }
        />
      </div>
      <div className="">
        <h2 className="font-bold">{teamMember.name}</h2>
        <h5 className="mb-1 md:text-sm">{teamMember.role}</h5>
        <p className="md:text-sm">{teamMember.bio}</p>
      </div>
    </div>
  );
};

export default Team;
