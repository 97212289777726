//@ts-nocheck
import React from "react";

const Traction = ({ traction }) => {
	return (
		<div>
			<h1 className='text-xl font-bold mb-2'>Market Traction</h1>
			<p>{traction || "No data"}</p>
		</div>
	);
};

export default Traction;
