import Header from "../../components/header";
import useAxios from "../../api/useAxios";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import {
  Avatar,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BASE_API_URL } from "../../api/useAxios";
import { BiCheckCircle } from "react-icons/bi";

export default function Review() {
  const [campaign, setCampaign] = useState(null);
  const [id, setId] = useState(null);
  const [state, setState] = useState("review");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [resG, errorG, msgG, isLoadedG, isLoadingG, apiCall] = useAxios(
    `/admin/campaigns?type=${state}`,
    "get"
  );

  const [resV, errorV, msgV, isLoadedV, isLoadingV, verifycamp] = useAxios(
    `/admin/id/accept`,
    "post"
  );

  const [resS, errorS, msgS, isLoadedS, isLoadingS, suspendcamp] = useAxios(
    `/admin/id/suspend-campaign`,
    "patch"
  );

  const GetCampaign = () => {
    apiCall(null, (res) => {
      setCampaign(res);
    });
  };

  const SuspendCampaign = () => {
    const url = `/admin/${id}/suspend-campaign`;
    suspendcamp(
      null,
      (res) => {
        GetCampaign();
        toast({
          title: `You have successfully ${
            res == 1 ? "suspended" : "unsuspended"
          } the campaign`,
          status: "success",
          isClosable: true,
        });
      },
      url
    );
  };

  const handleVerify = () => {
    const url = `/admin/${id}/accept`;
    verifycamp(
      null,
      (res) => {
        GetCampaign();
        onClose();
        toast({
          title: `You have successfully approved withdrawal request`,
          status: "success",
          isClosable: true,
        });
      },
      url
    );
  };

  useEffect(() => {
    GetCampaign();
  }, [state]);

  const Campaign = (props) => {
    const res = props.data;
    return res.map((val, index) => {
      return (
        <div
          key={index}
          className="h-auto p-3 md:p-4 space-y-2 border-b hover:bg-slate-100"
        >
          <div className="flex space-x-4">
            <Avatar
              size="md"
              name={val.name}
              src={`${BASE_API_URL}/download/${val.picture}`}
            />
            <div className="w-full flex justify-between items-center rounded">
              <div className="flex-rows items-center space-y-2 w-full">
                <div className="flex items-center justify-between">
                  <div className="grid">
                    <p className="text-sm font-bold capitalize">{val.name}</p>
                    <p className="text-xs capitalize">{val.remaining}</p>
                  </div>
                </div>
                <div className="w-full">
                  <p className="text-xs font-semibold ">
                    {val.fund_details?.slice(0, 100)}
                  </p>
                </div>
                <div className="flex space-x-1 md:space-x-4">
                  <Link to={`/company/${val.company_id}`}>
                    <Button
                      title="Startup"
                      className=" !normal-case"
                      hollow={true}
                    />
                  </Link>
                  <Link to={`/campaign/${val.id}`}>
                    <Button
                      title="Campaign"
                      className=" !normal-case"
                      hollow={true}
                    />
                  </Link>
                </div>
              </div>
              <div className=" md:px-4 flex items-start md:items-center justify-end space-x-4">
                {props.campaignState === "review" ? (
                  <BiCheckCircle
                    onClick={() => {
                      onOpen();
                      props.StateChange(val.id);
                    }}
                    size={40}
                    className="cursor-pointer text-sumuni-primary"
                  />
                ) : props.campaignState === "approved" ? (
                  <Button
                    handleClick={() => {
                      props.StateChange(val.id);
                      SuspendCampaign();
                    }}
                    loading={isLoadingS}
                    title="Suspend"
                    hollow={true}
                  />
                ) : props.campaignState === "suspended" ? (
                  <Button
                    handleClick={() => {
                      props.StateChange(val.id);
                      SuspendCampaign();
                    }}
                    loading={isLoadingS}
                    title="Activate"
                    hollow={true}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="relative w-full min-h-screen pb-16  bg-sumuni-gray ">
      <div className="bg-white z-30 md:px-12 px-2">
        <Header />
      </div>
      <div className="min-h-[80vh] w-full rounded md:px-12 px-2">
        <div className="hidden h-[7vh] mt-4 border-b-[0.1rem] md:flex items-center space-x-2">
          <p className="px-6 font-bold text-2xl">Campaign</p>
          <p
            onClick={() => {
              setState("review");
            }}
            className={`font-bold cursor-pointer ${
              state == "review" ? "text-sumuni-primary" : ""
            }`}
          >
            Review
          </p>
          <p
            onClick={() => {
              setState("approved");
            }}
            className={`font-bold cursor-pointer ${
              state == "approved" ? "text-sumuni-primary" : ""
            }`}
          >
            Approved
          </p>
          <p
            onClick={() => {
              setState("suspended");
            }}
            className={`font-bold cursor-pointer ${
              state == "suspended" ? "text-sumuni-primary" : ""
            }`}
          >
            Suspended
          </p>
        </div>
        <div className="min-h-[70vh] md:px-6 border-b-[0.1rem] overflow-y-auto">
          <Loading
            isLoading={isLoadingG}
            isLoaded={isLoadedG}
            error={errorG}
            msg={msgG}
            res={resG}
            tryAgain={GetCampaign}
            output={
              <>
                {resG ? (
                  <Campaign
                    data={campaign}
                    StateChange={setId}
                    campaignState={state}
                  />
                ) : (
                  ""
                )}
              </>
            }
          />
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent className="flex items-center justify-center h-64">
          <ModalCloseButton />
          <div className="space-y-6">
            <div className="flex items-center ">
              <p className="text-sm font-bold">
                Are You Sure You Want To Verify The Campaign?
              </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <Button
                handleClick={handleVerify}
                title="Yes"
                loading={isLoadingV}
              />
              <Button handleClick={onClose} title="No" hollow={true} />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}
