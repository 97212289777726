//@ts-nocheck
import React from "react";

const Fundraising = ({ data }) => {
  return (
    <div>
      <h1 className="font-bold mb-2">Fundraising Description</h1>
      <div className="divide-y divide-slate-200">
        <div className="grid grid-cols-2 gap-2 py-2">
          <p>Round Type:</p>
          <p>{data.roundType || 0}</p>
        </div>
        <div className="grid grid-cols-2 gap-2 py-2">
          <p>Targeted Funding Size:</p>
          <p>ETB {data.fundingSize || 0}</p>
        </div>
        <div className="grid grid-cols-2 gap-2 py-2">
          <p>Raised to date:</p>
          <div>
            <p>ETB {data.raisedToDate || 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fundraising;
