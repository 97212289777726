import { extendTheme } from "@chakra-ui/react";

// declare a variable for fonts and set our fonts. I am using Inter with various backups but you can use `Times New Roman`. Note we can set different fonts for the body and heading.

const Theme = extendTheme({
  colors: {
    brand: {
      50: "#fa2929",
      100: "#fa2929",
      200: "#fa2929",
      300: "#fa2929",
      400: "#fa2929",
      500: "#fa2929",
      600: "#fa2929",
      700: "#fa2929",
      800: "#fa2929",
      900: "#fa2929",
    },
  },

  styles: {
    global: {
      // styles for the `body`
      body: {
        fontFamily: "Raleway",
      },
      // styles for the `a`
    },
  },
});
// export our theme
export default Theme;
