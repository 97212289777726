//@ts-nocheck
import React from "react";

const Fund = ({ fund }) => {
  return (
    <div>
      <h1 className="text-xl font-bold mb-2">Use of Fund</h1>
      <p>{fund || "No data"}</p>
    </div>
  );
};

export default Fund;
